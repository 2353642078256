// Background colors
$base-color: #251a52 !important;
$base-background: white !important;
$base-background-acc: white;
$hightlight-color: #dc2079;
$hightlight-background-color: #edf0f3;
$hover-background-color: #eaf7f3;
$border-color: #f1f5f6;
$input-border-color: #a1a7a8;
$shade-background: #edf0f3;
$link-color: #0073e7;
$active-container-background: #0066d7 !important;


$tick-unseen: #575757;
$tick-seen: #177fe8;

$vacancy-back: #dbf1eb;
$messages-back: #dbf1eb;
$shortlist-back: #ececec;

$btn-border-radius: 25px;

// Font colors
$base-font: #251a52;
$font-family: "Open Sans",Helvetica,san-serif;

$homeMaxWidth: 1200px;
$paPadding: 2rem;

$progressBarColor: #e7f2ef;


@import 'styles/home';


.paMessages {
   // border-top: 2px solid $border-color;
   min-height: 600px;
}

.plain-btn {
   word-wrap: break-word;
   line-break: anywhere;
   display: inline-block;
   line-height: normal;
}

.next-btn,
.prev-btn {

   padding: 0;
   border: none;
   display: flex;
   justify-content: center;
   align-items: center;

   i.fas {
      margin-left: 0;
      margin: 0;
      padding: 0;
      font-size: 20px;
      line-height: 20px;
   }
}

.carousel-nav {
   display: flex;
   gap: 1rem;

   button{
      border: none;
      cursor: pointer;

      &:focus{
         padding: 1px 6px;
         border: none;
      }
   }

   .dots{
      button{
         &:focus{
            padding: 0;
            border: none;
         }
      }

      .activeSlide{
         &:focus-visible{
            border: #fff solid 2px;
         }
      }
   }

   span {
      &:hover {
         cursor: pointer;
      }
   }

   .dot{
      padding: 0;
   }
}