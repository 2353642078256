@import 'styles/components/loader';

.plain-btn{
  border: none;
  color: $base-background;
  background-color: $active-container-background;
  padding: 10px 25px;
  border-radius: $btn-border-radius;
  margin-top: .5rem;
  font-weight: 600;
  font-size: 1.2rem;
  text-decoration: none;

  &:hover{
    cursor: pointer;
  }
}

.secondary-btn{
  border: none;
  color: $link-color;
  background-color: $base-background;
  padding: 10px 25px;
  border-radius: $btn-border-radius;
  margin-top: .5rem;
  font-weight: 600;
  font-size: 1.2rem;

  &:hover{
    cursor: pointer;
  }
}

.third-btn{
  border: none;
  color: $link-color;
  background-color: $shade-background;
  padding: 10px 25px;
  border-radius: $btn-border-radius;
  margin-top: .5rem;
  font-weight: 600;
  font-size: 1.2rem;

  &:hover{
    cursor: pointer;
  }
}

.carousel-container {
    position: relative;
    // max-width: 800px;
    margin: 0 auto;
    background-color: $shortlist-back;
  }
  
  .carousel {
    display: flex;
    overflow: hidden;
  }
  
  .slide {
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;

    .slide-img{
      width: 50%;
      box-sizing: border-box;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }  
    }

    .slide-text{
      width: 50%;
      text-align: left;
      box-sizing: border-box;
      padding: 7rem 9rem 5rem 7rem;
      position: relative;
      padding-left: 7%;

      h1{
        font-weight: 700;
        margin-bottom: 2rem;
        color: $base-color;
        font-size: 2.5rem;
        line-height: 3rem;
      }

      h2{
        font-weight: 700;
        margin-bottom: 2rem;
        color: $base-color;
        font-size: 2.5rem;
        line-height: 3rem;
      }

      p{
        font-weight: 600;
        font-size: 1rem;
        line-height: 2rem;
        color: $base-color;
        margin-bottom: 2rem;
      }

    }

    
  }
  
  
  .carousel-nav {
    position: absolute;
    bottom: 3rem;
    left: 57%;
  }
  
  .prev-btn{
    font-size: 20px;
    cursor: pointer;
    margin: 0 10px;
    background-color: $base-background;
    width: 26px;
    border-radius: 100%;
    height: 26px;
    position: absolute;
    left: 40px;
    top: 50%;

    .fa-angle-left{
      margin-left: 7px;
      margin-top: 4px;
    }
  }

  .next-btn {
    font-size: 20px;
    cursor: pointer;
    margin: 0 10px;
    background-color: $base-background;
    width: 26px;
    border-radius: 100%;
    height: 26px;
    position: absolute;
    right: 40px;
    top: 50%;

    .fa-angle-right{
      margin-left: 9px;
      margin-top: 3px;
    }
  }
  
  .dots {
    display: flex;
    align-items: center;
  }
  
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: $base-background;
    margin: 0 7px;
    cursor: pointer;
  }

  .activeSlide{
    background-color: $base-color;
  }

  .looking-for-container{
    margin-left: 14%;
    margin-right: 14%;

    h2{
        text-align: center;
        margin: 5rem 0 3rem;
        font-weight: 700;
        font-size: 2.5rem;
        font-family: inherit;
        color: $base-color;
    }

    .looking-for-content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5rem;

        .looking-for-tile{
            text-align: center;
            width: 30%;
            justify-content: space-between;
            display: flex;
            flex-direction: column;

            h3 {
              font-weight: 700;
              padding: 1rem;
              font-size: 1.25rem;
              color: $base-font;
              font-family: inherit;
              line-height: normal;
            }

            p {
              padding: 0 1rem;
              margin-bottom: 3rem;
              font-size: 1rem;
              color: $base-font;
              font-family: inherit;
              line-height: normal;
            }

            img{
              width: auto;
              max-width: 100%;
            }

        }
    }
  }

  .available-container{
    background-color: $hightlight-background-color;
    padding-left: 14%;
    padding-right: 14%;

    h2{
      text-align: center;
      padding: 4rem 0 3rem;
      font-weight: 700;
      font-size: 2.5rem;
      color: $base-color;
      font-family: inherit;
    }
    
    .available-content{
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 5rem;
      column-gap: 10%;

      .available-tile{
        width: 60%;
        display: flex;
        align-items: center;
        border-radius: 10px;
        justify-content: center;

        .plain-btn{
          background-color: $base-background;
          color: $active-container-background;
          display: block;
        }

        .available-tile-text{
          background-color: $active-container-background;
          color: $base-background-acc;
          width: 60%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          border-radius: 10px 0 0 10px;
          padding: 2rem;
          height: 250px;
          box-sizing: border-box;
          margin: 0;

          h3,
          .h3{
            font-size: 2.5rem;
            margin: 0;
            font-weight: 700;
            font-family: inherit;
            line-height: 1;
          }

          p{
            font-size: 1rem;
            font-family: inherit;
          }

          div{
            margin-top: 1rem;
          }

        }

        .available-tile-image{
          max-width: 40%;
          box-sizing: border-box;
          height: 250px;

          img{
            width: 100%;
            height: 250px;
            box-sizing: border-box;
            // margin-top: 1px;
            object-fit: cover;
            border-radius: 0 10px 10px 0;
          }
        }
      }

    }
  }
  
  @media screen and (min-width: 1024px) and (max-width: 1600px){

    .slide {
      .slide-text{
        padding: 4rem 6rem 7rem;
      }
    }

  }

  @media screen and (min-width: 768px) and (max-width: 1024px){

    .slide {
      flex-direction: column;

      .slide-img{
        width: 100%;
      }

      .slide-text{
        width: 100%;
        padding: 4rem 2rem 7rem;
      }
    }

    .carousel-nav{
      left: 42%;
    }

    .next-btn, .prev-btn{
      display: none !important;
    }

    .looking-for-container{
      .looking-for-content{
        flex-direction: column;
        align-items: center;
        row-gap: 3rem;
  
        .looking-for-tile{
          width: 100%;
          img{
            width: auto;
          }
        }
      }
    }

    .available-container{
      .available-content{
        flex-direction: column;
        row-gap: 2rem;
  
        .available-tile{
          width: 100%;
        }
      }
    }

  }

  @media screen and (min-width: 320px) and (max-width: 767px){

    .slide {
      flex-direction: column;

      .slide-img{
        width: 100%;
      }

      .slide-text{
        width: 100%;
        padding: 4rem 2rem 7rem;
      }
    }

    .carousel-nav{
      left: 42%;
    }

    .next-btn, .prev-btn{
      display: none !important;
    }

    
    .looking-for-container{
      .looking-for-content{
        flex-direction: column;
        align-items: center;
        row-gap: 3rem;
  
        .looking-for-tile{
          width: 100%;

          img{
            width: auto;
          }
        }
      }
    }
    
    .available-container{
      .available-content{
        flex-direction: column;
        row-gap: 2rem;
  
        .available-tile{
          width: 100%;
          flex-direction: column;

          .available-tile-text{
            border-radius: 10px 10px 0 0;
            width: 100%;
          }

          .available-tile-image{
            width: 100%;
            max-width: 100%;
            margin-top: 0;
            padding: 0;

            img{
              margin: 0;
              border-radius: 0 0 10px 10px;
            }
          }
        }
      }
    }

  }
  

  .sign-up-tile {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      font-weight: 700;
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }
  }

  .testimonial-tile {

    display: flex;

    img {
      border-radius: 10px;
      height: max-content;
    }

    div {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem;

      h3 {
        font-weight: bold;
        font-size: 1.2rem;
      }

      .h4 {
        font-weight: bold;
        margin-bottom: 1rem;
      }

      p {
        font-size: 0.9rem;
      }

    }
  }


  // Contrast

  .contrast {

    .workas-btn{
      color: #ff0 !important;
    }

    .home{
      background-color: #000 !important;
    }

    .carousel-container{
      background-color: #000 !important;

      .slide-text{

        h1{
          color: #ff0 !important;
        }

        h2{
          color: #ff0 !important;
        }

        p{
          color: #ff0 !important;
        }
        
      }

      .next-btn, .prev-btn{
        border: 2px solid #ff0;
        background-color: #000 !important;
        color: #ff0 !important;
      }

      .plain-btn{
        border: 2px solid #ff0;
        background-color: #000 !important;
        color: #ff0 !important;
      }

      .activeSlide{
        background-color: #ff0 !important;
      }
    }

    .looking-for-container{
      background-color: #000 !important;
      color: #ff0 !important;

      h3, p{
        color: #ff0 !important;
      }

      .plain-btn{
        border: 2px solid #ff0;
        background-color: #000 !important;
        color: #ff0 !important;
      }
    }

    .available-container{
      background-color: #000 !important;
      color: #ff0 !important;

      .available-tile{
        border: 2px solid #ff0;
        background-color: #000 !important;
        color: #ff0 !important;

        .available-tile-text{
          background-color: #000 !important;

          h3, .h3{
            color: #ff0 !important;
          }

          p{
            color: #ff0 !important;
          }
        }

        .plain-btn{
          border: 2px solid #ff0;
          background-color: #000 !important;
          color: #ff0 !important;
        }
      }
      

      
    }

    .looking-for-container-er{
      background-color: #000 !important;
      color: #ff0 !important;

      .looking-for-tile{
        border: 2px solid #ff0;
        background-color: #000 !important;
        color: #ff0 !important;

        h3{
          color: #ff0 !important;
        }

        .available-tile-text{
          background-color: #000 !important;

          h3, .h3{
            color: #ff0 !important;
          }

          p{
            color: #ff0 !important;
          }
        }

        .plain-btn-er{
          border: 2px solid #ff0 !important;
          background-color: #000 !important;
          color: #ff0 !important;
        }
      }

      img{
        background-color: #ff0 !important;
      }
      

      
    }

    .available-container-er{
      background-color: #000 !important;
      color: #ff0 !important;

      .available-tile{
        border: 2px solid #ff0;
        background-color: #000 !important;
        color: #ff0 !important;

        .available-tile-text{
          background-color: #000 !important;

          h3, .h3{
            color: #ff0 !important;
          }

          p{
            color: #ff0 !important;
          }
        }

        .plain-btn-er{
          border: 2px solid #ff0 !important;
          background-color: #000 !important;
          color: #ff0 !important;
        }
      }
      

      
    }

    .news-container-er{
      background-color: #000 !important;
      color: #ff0 !important;

      .available-tile{
        border: 2px solid #ff0;
        background-color: #000 !important;
        color: #ff0 !important;

        .available-tile-text{
          background-color: #000 !important;

          h3, .h3{
            color: #ff0 !important;
          }

          p{
            color: #ff0 !important;
          }
        }

        .plain-btn-er{
          border: 2px solid #ff0 !important;
          background-color: #000 !important;
          color: #ff0 !important;
        }
      }
      

      
    }
  }


